<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div>
          <i18n path="objective.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("objective.title") }}</strong>
            </template>
          </i18n>
        </div>

        <ReadMore>
          <div class="mb-4">
            <ol>
              <li
                v-for="(item, i) in step4OrderedList"
                :key="i"
                v-text="item"
              ></li>
            </ol>
          </div>

          <div class="bsat__paragraph">{{ $t("objective2") }}</div>
        </ReadMore>

        <div>
          <i18n path="action.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("action.title") }}</strong>
            </template>
          </i18n>
        </div>

        <div>
          <i18n path="material.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("material.title") }}</strong>
            </template>
          </i18n>
        </div>
      </div>
      <a :href="materialFile" target="_blank" download>
        <v-btn
          depressed
          width="150"
          height="26"
          class="bsat__btn"
          dark
          color="dark"
          >{{ $t("download") }}</v-btn
        >
      </a>
      <br />
      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "action": {
      "text": "{title} Use this template to complete a SWOT matrix for each of the mentioned factors.",
      "title": "Action: "
    },
    "downloadAll": {
      "link": "click here",
      "text": "*To download all the materials of Phase 1 as a zip file please {link}"
    },
    "material": {
      "text": "{title} Template document for a SWOT analysis of the region against seven key factors in Bioeconomy.",
      "title": "Material available:"
    },
    "objective": {
      "text": "{title} To undertake a SWOT analysis of the region. SWOT stands for Strengths, Weaknesses, Opportunities, and Threats, The information collected in previous steps can be thoroughly analysed following the SWOT method against 7 key factors to assess the regional bioeconomy potential:",
      "title": "Objective:"
    },
    "objective2": "As a result, you will be able to use this SWOT analysis to assess your region’s current position before you decide on the new or updated Bioeconomy strategy. A deeper knowledge of the region according to seven important areas to develop bioeconomy. Following this analysis, you can identify gaps and overlaps in the region, as well as main value chains",
    "orderedList": [
      "Availability and use of resources",
      "Infrastructure and industrial factors",
      "Research and innovation",
      "Market/Economic aspects",
      "Public and institutional support/Governance/Policy framework",
      "Funding",
      "Social and environmental aspects"
    ],
    "title": "Step 4: SWOT analysis"
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
export default {
  name: "Phase1Step4",
  components: {
    ReadMore
  },
  data() {
    return {
      materialFile: require("@/assets/files/1_1_4_SWOT_template_per_factor.docx")
        .default,
      zip: require("@/assets/files/Phase1_Files.zip").default
    };
  },
  computed: {
    step4OrderedList() {
      return Object.values(this.$t("orderedList"));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors";
li {
  color: $dark;
}
</style>
